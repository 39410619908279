import { Product } from "../../domain/Product";
import { ProductStore } from "../../domain/ProductStore";
import { Store } from "../../domain/Store";
import {
  getStores,
  postSearchOneStore,
  postSearchStores,
} from "../../services/api.service";

var pluralize = require("pluralize");

export const validateStoreAddress = (address: string) => {
  if (!address) {
    return `does not have address`;
  }

  // Basic checks for common "bad" patterns
  const badPatterns = [
    /^\d+$/, // Only numbers (no street name)
    /^[^a-zA-Z0-9]+$/, // Only special characters
    /^(\d{5}|)$/, // Only a US postal code
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i, // Only a canadian postal code
    /(confidential|N\/A)/i, // Common placeholders
  ];

  // Check if the address matches any known bad patterns
  for (const pattern of badPatterns) {
    if (pattern.test(address)) {
      return "bad format";
    }
  }

  // Check for a minimum length (arbitrary threshold)
  if (address.length < 5) {
    return "bad format";
  }

  // Check for the presence of at least two components (street and city)
  const commaComponents = address.split(",");
  const spaceComponents = address.split(" ");
  if (commaComponents.length + spaceComponents.length < 2) {
    return "bad format";
  }

  // If all checks pass, consider the address "good"
  return undefined;
};

const validateStore = async (store: Store, index: number) => {
  const row = index + 2;
  const addressError = validateStoreAddress(store.address);
  if (
    !!addressError &&
    (addressError !== "bad format" || (!store.city && !store.zipcode))
  ) {
    throw new Error(`Row ${row} ${addressError}`);
  }

  return store;
};

export const storeUploadValidation = async (
  storesToValidate: Store[],
  accessToken: string,
  states: { abbr: string; name: string }[],
  country: string,
  storeLabel: string,
  orgId: number | undefined,
  processID?: string
) => {
  if (!storesToValidate?.length) {
    throw new Error(`No ${pluralize(storeLabel)} to upload`);
  }

  const validatedItems = await Promise.all(
    storesToValidate.map((v, i) => validateStore(v, i))
  );
  let searchedStores: any[] = [];
  if (validatedItems?.length === 1) {
    searchedStores = await postSearchOneStore(
      accessToken,
      validatedItems.map((s) => {
        const { id, store_id, status, ...rest } = s;
        return { ...new Store(rest).buildForSearch(), order: rest.order };
      })[0],
      true,
      orgId,
      processID
    );
  } else {
    searchedStores = await postSearchStores(
      accessToken,
      (validatedItems || []).map((s) => {
        const { id, store_id, status, ...rest } = s;
        return new Store(rest).buildForSearch();
      }),
      true,
      undefined,
      orgId
    );
  }
  let foundStores = await getFoundStores(searchedStores, accessToken);
  return validatedItems
    ?.map((store, i) => {
      const searchedStore =
        searchedStores?.find((s: any) => s.order === i) || store.searched;
      const storeState = searchedStore?.state || store.state;
      const storeCountry = searchedStore?.country || store.country;
      const abbreviatedState =
        (storeState?.length === 2 ? storeState : undefined) ||
        states?.find(
          (s) =>
            s.name.toLowerCase().includes(storeState?.toLowerCase()) ||
            s.abbr.toLowerCase().includes(storeState?.toLowerCase())
        )?.abbr ||
        storeState;
      const abbreviatedCountry = storeCountry || country;
      const { id, order, store_id, store_validation, ...rest } =
        searchedStore || {};
      return {
        original: store,
        ...store,
        ...(rest || {}),
        country: abbreviatedCountry,
        state: abbreviatedState,
        searched: {
          ...(searchedStore || {}),
          store:
            foundStores?.find((s) => s.id === searchedStore?.parent_id) ||
            (searchedStore || {}).store,
          orgStore:
            foundStores?.find((s) => s.id === searchedStore?.org_store_id) ||
            (searchedStore || {}).store,
        },
      };
    })
    .filter((s) => !!s);
};

export const validateStoreProductRelationWithUploaded = (
  uploadedStores?: Store[],
  relations?: ProductStore[],
  products?: Product[],
  storeLabel = "Store"
) => {
  if (!relations || !relations.length) {
    throw new Error("No relations to validate");
  }
  if (!uploadedStores || !uploadedStores.length) {
    throw new Error(`No ${pluralize(storeLabel)} uploaded`);
  }
  if (!products || !products.length) {
    throw new Error("No products to validate relations");
  }
  return relations.filter((relation) => {
    const foundStore = !!uploadedStores.find(
      (store) => store.id === relation.store_id
    );

    const foundProduct = !!products.find(
      (product) => product.id === relation.product_id
    );
    if (!foundProduct) {
      throw new Error(
        `Did not find product for product<>${pluralize(storeLabel)} relation`
      );
    }
    return foundStore && foundProduct;
  });
};

export async function getFoundStores(
  searchedStores: any[],
  accessToken?: string
) {
  const foundIds = Array.from(
    new Set(
      (searchedStores || [])
        .map((s: any) => s.parent_id)
        .filter((s: string) => !!s)
    )
  );
  const foundOrgIds = Array.from(
    new Set(
      (searchedStores || [])
        .map((s: any) => s.org_store_id)
        .filter((s: string) => !!s)
    )
  );
  let foundStores: Store[] = [];
  if (foundOrgIds.length && accessToken) {
    foundStores = await getStores(
      [],
      [],
      [],
      [],
      [],
      undefined,
      true,
      accessToken,
      undefined,
      [],
      foundOrgIds
    );
  }
  if (foundIds.length && accessToken) {
    const _foundStores = await getStores(
      [],
      [],
      [],
      [],
      [],
      undefined,
      false,
      accessToken,
      undefined,
      [],
      foundIds
    );
    foundStores = foundStores.concat(_foundStores || []);
  }
  return foundStores;
}
