import Papa from "papaparse";
import XLSX from "xlsx";

class StoreFileParser {
  file: File;
  type: "kehe" | "unfi" | "default" = "default";
  parsedData: any;

  constructor(file: File) {
    this.file = file;
    this.type = this.detectFileType();
  }

  detectFileType() {
    const fileName = this.file.name.toLowerCase();
    if (fileName.includes("kehe")) {
      return "kehe";
    }
    if (fileName.includes("unfi")) {
      return "unfi";
    }
    return "default";
  }

  async parse() {
    this.parsedData = await new Promise((resolve, reject) => {
      const fileExtension = this.file.name.split(".").pop()?.toLowerCase();
      if (fileExtension === "csv") {
        this.parseCSV(resolve, reject);
      } else if (fileExtension === "xlsx") {
        this.parseXLSX(resolve, reject);
      } else {
        reject(new Error("Unsupported file format"));
      }
    });
    return this.parsedData;
  }

  parseCSV(resolve: (value: unknown) => void, reject: (reason?: any) => void) {
    Papa.parse(this.file, {
      header: true,
      complete: (results) => {
        resolve(results.data);
      },
      error: (error) => {
        reject(error);
      },
    });
  }

  parseXLSX(resolve: (value: unknown) => void, reject: (reason?: any) => void) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (!result) return reject(new Error("No target"));
      const data = new Uint8Array(result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData =
        XLSX.utils.sheet_to_json<{ [key: string]: any }>(worksheet);
      if (this.type === "kehe") {
        resolve(this.parseKeheFormat(jsonData));
      } else {
        resolve(jsonData);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(this.file);
  }

  parseKeheFormat(data: { [key: string]: any }[]) {
    const result = [];

    const headers = [
      "Retailer Area",
      "chain",
      "address",
      "Product",
      "Fill Rate",
      "Ordered",
      "Shipped",
      "Stores That Ordered An Item",
      "SKU Count",
    ];

    let lineResult: string[] = [];
    for (let i = 5; i < data.length; i++) {
      const line = data[i];

      if (line["__EMPTY_5"]) {
        // Product line
        lineResult = lineResult.slice(0, 3);
        lineResult.push(
          ...[
            `${line["__EMPTY_5"]}`,
            `${line["__EMPTY_10"]}`,
            `${line["__EMPTY_11"]}`,
            `${line["__EMPTY_13"]}`,
            `${line["__EMPTY_17"]}`,
            `${line["__EMPTY_18"]}`,
          ]
        );
        result.push([...lineResult]);
      } else if (line["__EMPTY"]) {
        // Retailer area line
        lineResult = [`${line["__EMPTY"]}`];
      } else if (line["__EMPTY_2"]) {
        // Chain line
        lineResult = lineResult.slice(0, 1);
        lineResult.push(
          `${line["__EMPTY_2"] === "INDEPENDENT" ? "" : line["__EMPTY_2"]}`
        );
      } else if (line["__EMPTY_3"]) {
        // Address line
        lineResult = lineResult.slice(0, 2);
        lineResult.push(`${line["__EMPTY_3"]}`);
      }
    }

    return result.map((line) =>
      line.reduce((acc, c, i) => ({ ...acc, [headers[i]]: c }), {})
    );
  }

  dataToCsvFile() {
    if (!this.parsedData) throw new Error("No data to convert to CSV");

    // Read the data
    const sheet = XLSX.utils.json_to_sheet(this.parsedData);
    const csv = XLSX.utils.sheet_to_csv(sheet);

    // Create a File object in memory
    const csvBlob = new Blob([csv], { type: "text/csv" });
    const csvFile = new File([csvBlob], `${this.file.name.split(".")[0]}.csv`, {
      type: "text/csv",
    });

    return csvFile;
  }
}

export default StoreFileParser;
